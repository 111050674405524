import React, { Fragment } from 'react';
import '@styles/styles.scss';
import styles from './styles.module.scss';
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten } from 'polished';
import {
  Container,
  CssBaseline,
  Grid,
  Box,
  Button,
  MuiThemeProvider,
  createMuiTheme, Hidden,
} from '@material-ui/core';
library.add(faTwitter, faFacebook);

const theme = createMuiTheme({
	overrides: {
		MuiButton: {
			root: {
				boxShadow: 'none',
			},
			label: {
				fontWeight: 600,
				fontFamily: ['vaccine', 'Helvetica', 'sans-serif'].join(','),
				textTransform: 'none',
				fontSize: '1rem',
			},
			contained: {
				boxShadow: 'none',
				borderRadius: 0,
				padding: '0.75rem 1.75rem',
				backgroundColor: '#000000',
				color: '#ffffff',
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: lighten(0.2, '#000000'),
				},
			},
		},
	},
	typography: {
		fontFamily: ['vaccine', 'Helvetica', 'sans-serif'].join(','),
		headings: {
			fontFamily: ['fairwater-script', 'Helvetica', 'sans-serif'].join(','),
			fontWeight: 700,
		},
		h1: {
			fontSize: '2.5rem',
			fontWeight: 600,
		},
		h2: {
			fontSize: '2rem',
			fontWeight: 600,
		},
	},
	palette: {
		primary: {
			main: '#6FD18A',
		},
	},
});

const Home = () => {
	return (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<Grid container style={{ height: '100vh' }}>
				<Grid item xs={12} md={6} style={{ height: '100%' }}>
					<div className={styles.wrapper}>
						<Container maxWidth="md" style={{ height: '100%' }}>
							<Box px={3} height="100%">
								<Grid
									style={{ height: '100%', flexGrow: 1 }}
									container
									alignItems="flex-end"
								>
									<Grid item xs={12}>
										<div className={styles.content}>
											<Grid
												className={styles.heading}
												container
												spacing={3}
												alignItems="center"
											>
												<Grid item>
													<h1>Poitevins</h1>
												</Grid>
												<Grid item>
													<ul>
														<li>
															<a
																aria-label="Facebook"
																target="_blank"
																rel="noopener noreferrer"
																href="https://www.facebook.com/poitevins/"
															>
																<FontAwesomeIcon
																	icon={['fab', 'facebook']}
																/>
															</a>
														</li>
													</ul>
												</Grid>
											</Grid>

											<h2>
												Book a relaxing break away at our small family-run
												holiday farmhouse in southern france.
											</h2>
											<p>
												Our website is in the making, check back soon for
												more information, or chat with us on Facebook to
												enquire directly!
											</p>

											<Box mt={3} />

											<Button
												component="a"
												href="mailto:info@poitevinsrusticfrance.com"
												variant="contained"
											>
												Contact us
											</Button>
										</div>
									</Grid>

									<Grid item xs="auto">
										<footer>
											<p>
												This website was created by{' '}
												<a href="https://joemethven.com">Joe Methven</a>.
											</p>
										</footer>
									</Grid>
								</Grid>
							</Box>
						</Container>
					</div>
				</Grid>
				<Hidden smDown>
					<Grid item xs={12} md={6} style={{ height: '100%' }}>
						<img
							className={styles.image}
							alt="Poitevins, St-Astier, Duras, France"
							src="/images/poitevins.jpg"
						/>
					</Grid>
				</Hidden>
			</Grid>
		</MuiThemeProvider>
	);
};

export default Home;
